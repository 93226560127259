import React from 'react';
import {Image, Segment,} from 'semantic-ui-react';
import profileImg1 from "../../assets/inselkammer.jpg";
import componentStyles from "../../templates/default.module.css";

const GreetingsBoard = () => (
    <Segment>
        <Image src={profileImg1} floated={'left'} size={'medium'} className={componentStyles.greetingsImg}/>
        <p>Liebe Leserin, lieber Leser,</p>
        <p>herzlich willkommen auf den Internetseiten der Deutschen Stiftung Kinderdermatologie. Gerne lade ich Sie ein, mehr über die Stiftung zu erfahren.</p>
        <p>Vielen Kindern und Jugendlichen ist es verwehrt, sorgenfrei zu leben. Das trifft insbesondere zu, wenn sie seit Geburt an der Haut schwerst erkrankt sind oder sich im Laufe ihres jungen Lebens chronische oder schwere Erkrankungen am größten menschlichen Organ entwickeln.
            In einer Welt aufzuwachsen, in der eine perfekte Haut erwartet wird, wo es selbstverständlich ist, sich in der eigenen Haut wohl fühlen zu können, brauchen gerade die kleinen und jungen hautkranken Patienten unsere warmherzige Zuwendung, Fürsorge und Verantwortung.</p>
        <p>Aber auch die Familien benötigen Hilfe, da sich ihr Alltag in der Gesellschaft wesentlich schwieriger gestaltet. Oftmals bedarf es einer aufwendigen Hauttherapie und -pflege, vieler Arztbesuche, vielleicht werden Krankenhausaufenthalte sogar nötig, individuelle Präventionsmaßnahmen oder speziell aus-gestattete Wohnräume, die zu Verzweiflung und Existenzsorgen führen können.</p>
        <p>Die aktuelle gesundheitliche Versorgung der Kinder und Jugendlichen gegenüber den Erwachsenen mit Hautkrankheiten zeigt eine erhebliche Schieflage in Praxis, Klinik, Bildung, Hochschullehre und Forschung.</p>
        <p>Die Deutsche Stiftung Kinderdermatologie hat sich zum Ziel gesetzt, dort zu unterstützen, wo Hilfe für hautkranke Kinder und Jugendliche dringend benötigt wird, sie fördert dadurch somit den Weg für ein glücklicheres und erfolgversprechenderes Leben.</p>
        <p>Kinder sind unsere Zukunft und brauchen eine Zukunft. Wir alle können etwas dafür tun. Helfen auch Sie mit, dass Kinder und Jugendliche ein Stück Fröhlichkeit und Lebensqualität zurückbekommen.</p>
        <p>Mein besonderer Dank gilt der Unterstützung unserer Stiftungsziele durch die Arbeitsgemeinschaft Pädiatrische Dermatologie, den Berufsverband der Deutschen Dermatologen, der Deutschen Dermatologischen Gesellschaft und des Netzwerkes interdisziplinäre pädiatrische Dermatologie, unserem ersten Botschafter Herrn Sky du Mont, unserem Schirmherrn Prof. Dr. med. Dr. phil. Johannes Ring und unseren Freunden, Förderern, Spendern und Sponsoren.</p>
        <p>Mit freundlichen Grüßen</p>
        <p>Ihre</p>
        <p>Dr. med. Nicole Inselkammer</p>
    </Segment>

);

export default GreetingsBoard;