import React from "react";
import '../../styles/semantic.min.css';
import MainNavigation from "../../components/navigation/MainNavigation";
import Footer from "../../components/footer/Footer";
import GreetingsBoard from "../../components/about-us/GreetingsBoard";
import LayoutContainer from "../../container/LayoutContainer";
import SEO from "../../components/seo/Seo";
import PageHeader from "../../components/utils/PageHeader";
import AboutUsNavigation from "../../components/navigation/AboutUsNavigation";
import {Container, Grid, Segment} from "semantic-ui-react";
import MobileTabletContainer from "../../container/MobileTabletContainer";
import DesktopContainer from "../../container/DesktopContainer";
import MobileBgWrapper from "../../container/MobileBgWrapper";
import layout from "../../templates/layout.module.css";

export default () =>
    <LayoutContainer>
        <SEO title={'Grußwort Vorstandsvorsitzende'}/>

                <MobileTabletContainer>
                    <MainNavigation/>
                    <PageHeader title={'Grußwort Vorstandsvorsitzende'}
                                subHeader={'Grußwort der Stiftungsgründerin und Vorstandsvorsitzenden Dr. med. Nicole Inselkammer'}/>
                    <MobileBgWrapper>
                        <Container>
                            <Segment>
                        <GreetingsBoard/>
                            </Segment>
                        </Container>
                    </MobileBgWrapper>
                    <Footer/>
                </MobileTabletContainer>
                <DesktopContainer>
                    <div className={layout.pageWrapper}>
                    <MainNavigation/>
                    <Container fluid>
                    <PageHeader title={'Grußwort Vorstandsvorsitzende'}
                                subHeader={'Grußwort der Stiftungsgründerin und Vorstandsvorsitzenden Dr. med. Nicole Inselkammer'}/>
                    <Grid centered columns={2}>
                        <Grid.Column width={11}>
                                <GreetingsBoard/>
                            <AboutUsNavigation/>
                        </Grid.Column>
                        <Grid.Column width={4}/>
                    </Grid>
                    </Container>
                    <Footer/>
                    </div>
                </DesktopContainer>

    </LayoutContainer>